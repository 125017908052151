import { closeToast, showFailToast, showLoadingToast } from 'vant';

import { login } from '@eaphone/auth';
import { Auth } from '@eaphone/storage';

function loginLogic(next, to) {
  showLoadingToast('正在登录...');

  const {
    path,
    query: { accessToken, ...query },
  } = to;

  login({ code: accessToken, type: 'yijia' })
    .then(() => {
      closeToast();

      next({ path, query, replace: true });
    })
    .catch((error) => {
      console.error(error);

      showFailToast(error.message);

      next({ name: 'error-401' });
    });
}

export function loginHook(to, from, next) {
  if (to.meta.auth) {
    if (to.query.accessToken) {
      loginLogic(next, to);
    } else if (Auth.token) {
      // 已有 token
      next();
    } else {
      next({ name: 'error-401' });
    }
  } else {
    next(); // 不做任何处理
  }
}

export function usePageConfig() {}
