export default {
  component: () => import('./page.vue'),
  path: '/device/control/:serial?',
  name: 'device-control',
  meta: {
    title: '设备控制',
    auth: true,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
    },
  },
};
